import { render, staticRenderFns } from "./GyartoiGaranciasForm.vue?vue&type=template&id=5f31ca2b&scoped=true&"
import script from "./GyartoiGaranciasForm.vue?vue&type=script&lang=js&"
export * from "./GyartoiGaranciasForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f31ca2b",
  null
  
)

export default component.exports