<template>
  <div v-frag>
    <hero-with-image :title="brand.brandName" subtitle="warrantyRepairForm" :image="brand.imagePath"></hero-with-image>
    <template v-if="brand.brandId">
      <gyartoi-garancias-form :brand="brand" :brand-name="brandName" :brand-id="brandId"></gyartoi-garancias-form>
    </template>
  </div>
</template>

<script>
import GyartoiGaranciasForm from 'src/components/forms/GyartoiGaranciasForm';
import HeroWithImage from "../../../components/titles/HeroWithImage.vue";

export default {
  name: 'ExternalBrandWarrantyFormPage',
  components: {
    HeroWithImage, GyartoiGaranciasForm
  },
  props: {
    brandId: {
      type: Number,
      default: 999,
    },
    brandName: {
      type: String,
      default: 'EMPTY',
    },
  },
  async beforeMount() {
    if (this.$store.state.brandManagement.selectedBrand && this.brandId === this.$store.state.brandManagement.selectedBrand.brandId) {
      this.brand = this.$store.state.brandManagement.selectedBrand
    } else {
      this.brand = await this.getBrand();
    }
  },
  mounted() {
    // this.contactForm.sourceSystem = this.$route.name.substring(14);
  },
  data() {
    return {
      brand: {},
    }
  },
  methods: {
    async getBrand() {
      return await this.$store.dispatch('getBrandById', this.brandId);
    },
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
